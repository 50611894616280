.review-video .testimonial > div {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
}

.review-video .testimonial > div img {
    margin-right: 20px;
}

.review-video .testimonial .read-more-link {
    margin: 30px 50px 0;
}