﻿* {
    font-family: Lato;
}

.top_banner {
    background: url(/images/home-hero.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
}

section {
    padding: 50px 0;
}

.top_banner .row {
    display: flex;
    align-items: center;
}

.top_banner .item-content ul {
    margin-top: 10px;
}

.top_banner .item-content ul li {
    font-size: 24px;
    line-height: 1.5;
    display: flex;
    align-items: center;
    font-weight: 100;
}

.top_banner .item-content ul li img {
    margin-right: 10px;
}

.top_banner .btn-enroll {
    margin: 20px 0;
}

.btn-enroll.btn-lg {
    padding: 8px 50px 9px;
}

.btn-enroll {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 1.2px;
    background-color: #ff8700;
    border-radius: 999px;
}

.top_banner .device-images img {
    width: 100%;
}

.top_banner h1 {
    line-height: 1.3;
    letter-spacing: 1.4px;
}

section h1, section h2, section h3, section h4, section h5, section h6 {
    margin: 0;
    margin-bottom: 20px;
}

ul {
    padding-left: 0;
}

ul, li {
    list-style-type: none;
}

.top_banner .bbb-customer-logo {
    margin: 20px 0;
}

.top_banner .bbb-customer-logo img {
    width: 120px;
    margin-right: 10px;
}

.underline {
    text-decoration: underline;
}

.color-blue {
    color: #007bb6;
}

.color-red {
    color: #f05748;
}

.btn-red:hover {
    background-color: #c30115;
    color: white;
}

.btn-red {
    background-color: #fb001a;
    border-radius: 999px;
    color: white;
    padding: 6px 30px;
    min-width: 150px;
    line-height: 1.42857143 !important;
}

p {
    font-size: 18px;
    line-height: 2;
}

.faq .faqitem:first-child {
    padding-top: 0;
}

.faq .faqitem:last-child {
    border-bottom: none;
}

.faq .faqitem {
    padding: 20px 10px;
    border-bottom: 1px solid #aaa;
}

.faq div {
    font-size: 18px;
    line-height: 1.5;
}

.faq .faqitem .question {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}

footer {
    padding: 20px;
    background: #48515e;
}

footer .copyright-text {
    color: white;
    margin-top: 30px;
}

footer .row a {
    color: white;
    text-decoration: none;
    font-size: 16px;
    font-weight: 100;
    line-height: 1.8;
}

footer .row a i {
    margin-right: 5px;
}

.social .social-item {
    margin: 0 5px;
}

.social .social-item img {
    width: 80px;
}

iframe {
    width: 100%
}

.btn-register {
    background: linear-gradient(to bottom, #d53c39, #a32d22);
    color: white;
    text-transform: uppercase;
    box-shadow: 0 2px 4px grey;

    &:hover {
        background: linear-gradient(to top, #d53c39, #a32d22);
        color: white;
    }

    &:active, &:focus {
        color: white;
    }
}

#gotTicket {
    width: 218px;
    margin: 0 auto 20px auto;

    div {
        margin: 0;
        padding: 0;
    }

    .top {
        background: url('/images/gotTicket_top.png') 0 0 no-repeat;
        height: 31px;
    }

    .bot {
        background: url('/images/gotTicket_bot.png') 0 0 no-repeat;
        height: 10px;
    }

    .mid {
        background: url('/images/gotTicket_bg.png') repeat-y;
        width: 218px;
        padding: 10px 8px 5px 8px;
        text-align: left;
        position: relative;

        .btn_go {
            background: url('/images/gd_sprites.png') -85px -827px no-repeat;
            width: 32px;
            height: 22px;
            border: none;
            cursor: pointer;
            border: none;
            position: absolute;
            right: 30px;
            top: 10px;

            &:hover {
                background-position: -85px -849px;
            }
        }
    }
}

.spanish-offer {
    font-size: 20px;
    text-decoration: underline;
}

.spanish-offer a {
    color: #07b8fd;
}

.container_image {
    position: relative;

    &:before {
        content: '';
        background: rgba(255,255,255,.6);
        height: 80px;
        width: 80px;
        display: block;
        pointer-events: none;
        margin: -40px 0 0 -40px;
        border-radius: 100px;
        position: absolute;
        top: 50%;
        left: 50%;
        box-shadow: 0 0 20px 3px rgba(51,51,51,.8);
        transition: all .1s ease;
        z-index: 1;
        cursor: pointer;
    }

    &:hover {
        &:before {
            background: rgba(238,238,238,.8);
            box-shadow: 0 0 10px 3px rgba(51,51,51,.8);
            width: 100px;
            height: 100px;
            margin: -50px 0 0 -50px;
        }
    }

    &:after {
        cursor: pointer;
        pointer-events: none;
        content: '';
        width: 0;
        height: 0;
        line-height: 0;
        display: block;
        border-style: solid;
        border-width: 20px 0 20px 40px;
        margin: -20px 0 0 -15px;
        border-color: transparent transparent transparent #333;
        position: absolute;
        top: 50%;
        left: 50%;
        height: 0;
        width: 0;
        z-index: 2;
    }

    img {
        filter: brightness(.7);
    }
}