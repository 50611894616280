.course-content .container .row {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
}
.course-content .container .row:last-child {
    border-bottom: none;
}


.top-rated h3 {
    margin-bottom: 20px;
}

.course-content h3.color-blue {
    font-weight: 400;
}

.course-content p {
    font-size: 18px;
    line-height: 2;
}

.top-rated ul li {
    margin-bottom: 5px;
    font-size: 18px;
}

.top-rated ul li img {
    margin-right: 10px;
    width: 15px;
}

.top-rated .reason-text {
    color: #89898a;
}

.driving-course img {
    margin-right: 30px;
}

.course-content .image-description {
    display: inline-flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.course-content .image-description img {
    width: 100%;
}

.advantage img {
    margin-right: 10px;
    width: 15px;
}

.advantage .item {
    margin-bottom: 20px;
}