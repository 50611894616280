.we-also-offer .offer-items .offer-item {
    margin: 0 20px;
    padding: 0;
    text-decoration: none;
    cursor: pointer;
    color: inherit;
    margin-bottom: 10px;
}

@media (min-width: 992px) {
    .we-also-offer .offer-items .offer-item {
        width: calc(25% - 40px);
    }
}

@media (max-width: 992px) {
    .we-also-offer .offer-items .offer-item {
        margin: 0 0 10px 0 !important;
        width: 85% !important;
    }

    .offer-items {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.we-also-offer .offer-items .offer-item span {
    display: block;
}

.we-also-offer .offer-items .offer-item .card {
    text-align: center;
    padding: 15px;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px 5px #ddd;
}

.we-also-offer .offer-items .offer-item .card .text {
    font-size: 20px;
    font-weight: 600;
    height: 56px;
}

.we-also-offer .offer-items .offer-item .card .icon {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.we-also-offer .offer-items .offer-item .card .icon .line {
    height: 1px;
    border-bottom: 1px solid #f05748;
    width: 50px;
}

section h2 {
    margin-bottom: 40px;
}