.services-list {
    background-color: #eee;
    line-height: 1.42857143;
}

.services-list .container > div > div {
    text-align: center;
}

.services-list .container .icon {
    text-align: center;
}

.services-list .container .icon:hover {
    text-decoration: none;
}

.services-list .container .icon img {
    display: block;
    margin: auto;
    margin-bottom: 8px;
}