.partner-video i {
    margin: 0 5px;
}

.partner-video .check-list p {
    line-height: 1.5;
}

.partner-video #play_vid {
    width: 100%;
}

.partner-video .check-list p {
    line-height: 1.5;
    font-size: 18px;
}

.partner-video .check-list p i {
    font-family: 'FontAwesome';
}