/* Navigation */
#navigation {
    width: 100%;
    background-color: white;
    margin: auto;
    z-index: 999;
}

#navigation .navbar {
    max-width: 1200px;
    margin: auto;
    border: none;
    padding: 0 20px;
}

#navigation .navbar-header {
    display: flex;
    align-items: center;
}

#navigation .navbar-header button {
    margin-left: auto;
}

.navbar-default {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #e7e7e7;
    font-weight: bold;
    font-size: 18px;
}

.navbar-nav .action {
    background: #868d96;
    cursor: pointer;
}

.chat-button {
    cursor: pointer;
}

.navbar-nav .action.login {
    background: #48515e;
}

.navbar-default .navbar-nav > li.no-icon {
    display: none;
}

.navbar-default .navbar-nav > li.action {
    width: 135px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1130px) {
    .navbar-default .navbar-nav > li.no-icon {
        display: block;
    }

    .navbar-default .navbar-nav > li.action {
        display: none;
    }
}

.navbar-default .navbar-nav > li {
    margin: 15px;
}

.navbar-default .navbar-nav > li > a {
    padding: 0;
}

.navbar-default .navbar-nav > li.action {
    margin-right: 0;
}

.navbar-default .navbar-nav > li.login {
    margin-left: 0;
}

.navbar-default .navbar-nav > li.action > a {
    color: white;
    font-weight: normal;
}

.navbar-nav .action a i {
    display: block;
    font-size: 32px;
    margin-bottom: 8px;
}

.navbar-default .navbar-brand {
    height: auto;
}

@media (min-width: 768px) {
    .navbar-nav {
        display: flex;
        align-items: center;
        height: 140px;
    }
}

.navbar-brand.logo {
    position: relative;
}

@media (min-width: 981px) {
    .navbar-brand.logo {
        height: 140px;
    }

    .navbar-brand.logo img {
        height: 100%;
    }
}

.navbar-brand.logo span {
    position: absolute;
    left: 30%;
    bottom: 5px;
    font-size: 16px;
}

@media (max-width: 980px) and (min-width: 768px) {
    .navbar-default .navbar-brand img {
        height: 50px;
    }

    .navbar-nav {
        height: 85px;
    }

    .navbar-collapse {
        padding-left: 0 !important;
    }

    .navbar-right {
        margin-right: 0 !important;
    }

    #navigation .navbar {
        padding: 0 15px !important;
    }
}

@media (min-width: 768px) {
    #navigation {
        position: relative !important;
    }
}

@media (max-width: 768px) and (min-width: 501px) {
    .navbar-default .navbar-brand img {
        height: 50px;
    }
}

@media (max-width: 500px) {
    .navbar-default .navbar-brand img {
        height: 45px;
    }
}

/* Contact Phone */
.contact-phone {
    display: flex;
    align-items: center;
    border-top: 1px solid #666;
    border-bottom: 1px solid #666;
}

.contact-phone p {
    margin: 0;
    padding: 5px;
    font-size: 18px;
    line-height: 2;
}